.main-container {
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color 1.5s ease-in-out;
}
.main-container.revealed {
  background-color: #ffd1dc;
}
.btn {
  border: none;
  padding: 12px 48px;
  font-size: 22px;
  font-weight: 600;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  color: #ffffff;
}
.btn.fade {
  animation: fade 1s ease-in-out;
}
.btn.btn-shine {
  color: #ffffff;
  background-color: #ffd1dc;
  background-clip: unset;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s infinite ease-in-out;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  transition: color 1s ease-in-out;
}
.btn.fade.btn-shine {
  animation: fade 1s ease-in-out, shine 4s infinite ease-in-out;
}
.btn.btn-revealed {
  color: #000000;
  transition: all 1s ease-in-out;
  animation: none;
}
.btn.fade.btn-revealed {
  animation: fade 1s ease-in-out;
}
@keyframes shine {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #ffaec1;
  }
  100% {
    background-color: #ffffff;
  }
}
@keyframes fade {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}
